
































import axios from 'axios'
import { mountComponent } from 'library/helpers/dom'
import HelperModal from 'library/components/globals/modals/trace-helper'

export default

	data: ->
		error: false
		submitting: false
		noInputError: false
		badInputError: false
		lookup: false
		upc: ''
		lot: ''

	computed:
		helperImage: ->  @$store.state?.globals?.trace?.lookupHelperImage
		errorMessage: -> @$store.state?.globals?.trace?.error
		badResponseErrorMessage: -> @$store.state?.globals?.trace?.error2
		isDisabled: -> @upc.length == 0 || @lot.length == 0
		classes: -> [
			'error' if @error
		]

		btnLabel: -> if @submitting then 'Please Wait' else 'Trace My Bottle'

	methods:

		# temp auth to get past
		auth: ->
			@noInputError = false
			@badInputError = false
			@error = false

			if !@upc || !@lot
				@noInputError = true
				return

			# add query params only if they
			# aren't already set
			if !@$route.query.hasOwnProperty('UPC') && !@$route.query.hasOwnProperty('LOT')
				@addQueryParams()

			@submitting = true

			data = {
				upc: @sanitize @upc
				lot: @sanitize @lot
			}

			# if inputs, then lookup
			response = await axios.get(process.env.TRACE_ENDPOINT, params: data)

			# check if BottleDate has a value, and if not,
			# then assume that there's no data, so don't pass
			if response.data.length <= 0 || !response.data[0].BottleDate || response.data[0].Active == 0
				@badInputError = true
			else
				@$emit 'success', response?.data?[0]

			@submitting = false

		openModal: ->
			await mountComponent HelperModal,
				parent: this
				propsData:
					image: @helperImage

		reset: ->
			@lot = ''
			@upc = ''
			@noInputError = false
			@error = false
			@lookup = false
			@submitting = false
			@removeQueryParams(['UPC', 'LOT'])
			@$emit 'reset'

		sanitize: (input) -> input.replace(/[^\w\s]/gi, '')

		setAndAuth: ->
			@upc = @$route.query.upc || @$route.query.UPC
			@lot = @$route.query.lot || @$route.query.LOT
			@auth()

		addQueryParams: ->
			currentRoute = @$router.currentRoute
			newQuery = {
				...currentRoute.query
				upc: @upc
				lot: @lot
			}

			@$router.replace({ query: newQuery })

		removeQueryParams: (params) ->
			currentRoute = @$router.currentRoute
			newQuery = Object.keys(currentRoute.query).reduce((acc, key) ->
				if not params.includes(key) then acc[key] = currentRoute.query[key]
				acc
			, {})
			@$router.replace({ query: newQuery })


import { render, staticRenderFns } from "./trace.vue?vue&type=template&id=3a1f4a87&scoped=true&lang=pug&"
import script from "./trace.vue?vue&type=script&lang=coffee&"
export * from "./trace.vue?vue&type=script&lang=coffee&"
import style0 from "./trace.vue?vue&type=style&index=0&id=3a1f4a87&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a1f4a87",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TraceOlives: require('/opt/build/repo/nuxt-app/components/globals/trace/olives.vue').default,Wysiwyg: require('/opt/build/repo/node_modules/@bkwld/cloak/components/wysiwyg.coffee').default,TraceLookup: require('/opt/build/repo/nuxt-app/components/globals/trace/lookup.vue').default,CraftVisual: require('/opt/build/repo/node_modules/@bkwld/cloak/components/craft-visual.coffee').default})













import { clearAllBodyScrollLocks } from 'body-scroll-lock'

export default

	props:
		image: Array

	methods:
		handleDestroy: ->
			@$el.remove()


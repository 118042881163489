





































export default

	data: ->
		error: false
		lookup: false
		traceData: {}

	mounted: ->
		@$wait 100, =>
			if (@$route.query.hasOwnProperty('upc') || @$route.query.hasOwnProperty('UPC')) && (@$route.query.hasOwnProperty('lot') || @$route.query.hasOwnProperty('LOT'))
				@$refs.lookup.setAndAuth()
			else if @$route.query.hasOwnProperty('upc') || @$route.query.hasOwnProperty('UPC')
				@$refs.lookup.upc = @$route.query.upc || @$route.query.UPC

	computed:
		copy: -> @$store.state?.globals?.trace?.lookupCopy
		image: -> @$store.state?.globals?.trace?.lookupImage

	methods:

		# successful submission
		onSuccess: (data) ->
			@scrollTop()
			@traceData = data
			@lookup = true

		reset: ->
			@scrollTop()
			@lookup = false

			@$wait 100, =>
				@$refs.lookup.removeQueryParams(['UPC', 'LOT', 'upc', 'lot'])

		scrollTop: ->
			document.body.scrollTop = document.documentElement.scrollTop = 0














import olives from 'library/assets/images/trace-olives.png'
import olivesMobile from 'library/assets/images/trace-olives-mobile.png'

export default

	data: ->
		olives: olives
		olivesMobile: olivesMobile

